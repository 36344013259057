import React from 'react';
import {
  ArrayInput,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  useRecordContext,
} from 'react-admin';
import { useWatch } from 'react-hook-form';

const SelectPermissions = () => {
  // Para pegar os valores do form
  const permissionsArray = useWatch({ name: 'permissionsArray' });
  const record = useRecordContext();

  const [companies, setCompanies] = React.useState([]);

  React.useEffect(() => {
    const storedCompanies = localStorage.getItem('companies');
    if (storedCompanies) {
      setCompanies(storedCompanies.split(','));
    }
  }, []);

  const userCompanies = [];

  if (permissionsArray && permissionsArray.length > 0) {
    permissionsArray.forEach((company) => {
      if (company) userCompanies.push(company.companyUser);
    });
  }

  if (record && record.isAdmin) return null;

  return (
    <div
      style={{
        width: '400px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <ArrayInput
        source="permissionsArray"
        validate={required()}
        label="Empresas"
      >
        <SimpleFormIterator
          disableReordering
          disableAdd={
            permissionsArray
              ? permissionsArray.length >= companies.length
              : false
          }
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <ReferenceInput
                label="Empresa"
                source={getSource('companyUser')}
                record={scopedFormData}
                reference="companies"
                sort={{ field: 'id', order: 'ASC' }}
              >
                <SelectInput
                  validate={required()}
                  label="Empresa"
                  optionText={(option) => `${option.name}`}
                  optionDisabled={(option) => userCompanies.includes(option.id)}
                />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <ReferenceArrayInput
                style={{ width: '100%' }}
                label="Permissões"
                source={getSource('permissions')}
                record={scopedFormData}
                reference="permissions"
                filter={
                  scopedFormData && scopedFormData.companyUser
                    ? { company: scopedFormData.companyUser }
                    : null
                }
                perPage={100}
                sort={{ field: 'id', order: 'ASC' }}
              >
                <SelectArrayInput
                  validate={required()}
                  label="Permissões"
                  disabled={!(scopedFormData && scopedFormData.companyUser)}
                  optionText={(option) => {
                    if (
                      scopedFormData &&
                      scopedFormData.permissions &&
                      scopedFormData.permissions.includes(option.id)
                    ) {
                      return `✔ ${option.name}`;
                    }
                    return `${option.name}`;
                  }}
                />
              </ReferenceArrayInput>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};
export default SelectPermissions;
