import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  BulkDeleteButton,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  List,
  ListButton,
  NumberField,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useGetOne,
  useNotify,
  usePermissions,
  useRecordContext,
} from 'react-admin';

import {
  Boolean,
  Date,
  File,
  FileOne,
  Image,
  ImageOne,
  Number,
  RichText,
  Text,
} from './Fields';
import ToggleSecaoUser from './toggleSecaoUser';

const SecaoUserTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Seção {`"${record.title}"`}</span>;
};

const SecaoUserFilter = (props) => (
  <>
    <Filter {...props} style={{ width: '100%' }}>
      <TextInput
        label="Procurar"
        source="q"
        alwaysOn
        style={{ width: '100%' }}
        name="procurar"
      />
      <ReferenceInput
        label="Seção"
        source="sections"
        reference="company_sections"
        allowEmpty
        name="secao"
      >
        <SelectInput optionText="title" label="Seção" />
      </ReferenceInput>
    </Filter>
  </>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_SECAO_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

const MostraTitulo = () => {
  const record = useRecordContext();
  if (!record) return null;

  const texto = record.section_data.title || record.section_data.titulo || '';

  return <span>{texto}</span>;
};

export const SecaoUser = (props) => {
  const permissions = usePermissions();

  return (
    <List
      perPage={25}
      title="Adicionar na Seção"
      filters={<SecaoUserFilter />}
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <ToggleSecaoUser label="Ativo" source="disabled" />
        <NumberField label="ID" source="id" />
        <TextField label="Seção" source="sections.title" sortable={false} />
        <MostraTitulo label="Título" source="title" sortable={false} />
        <TextField label="Ordenação" source="order" />
        <TextField label="Seção Pai" source="upper_section" sortable={false} />

        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_SECAO_UPDATE')) && (
            <EditButton />
          )}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_SECAO_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

const SectionsGet = ({ section }) => {
  const company = localStorage.getItem('company');
  const { data, isLoading } = useGetOne('company_sections', {
    id: section,
    company,
  });

  if (isLoading) return <CircularProgress />;
  if (!data || !data.sections) return null;

  return (
    <>
      {data.upper_section && (
        <ReferenceInput
          source={`sections.${data.upper_section}`}
          disabled
          reference="company_sections"
          label="Seção Pai"
          filter={{ company }}
        >
          <AutocompleteInput
            fullWidth
            disabled
            source="upper_section"
            defaultValue={data.upper_section}
            label="Seção Pai"
            optionText={(choice) => `${choice.title}`}
          />
        </ReferenceInput>
      )}
      {!data.singular && (
        <TextInput
          type="number"
          source="order"
          label="Ordenação"
          validate={required()}
          defaultValue="1"
          parse={(value) => {
            if (value && value.length > 1 && value[0] === '0') {
              return value.slice(1);
            }
            if (value && value <= 1) return 1;
            return value;
          }}
        />
      )}
      {data.sections.data_type.map((dataType) => {
        switch (dataType.field) {
          case 'text':
            return (
              <Text
                label={dataType.label}
                source={`section_data.${dataType.key}`}
                key={dataType.key}
              />
            );
          case 'number':
            return (
              <Number
                label={dataType.label}
                source={`section_data.${dataType.key}`}
                key={dataType.key}
              />
            );
          case 'richText':
            return (
              <RichText
                label={dataType.label}
                source={`section_data.${dataType.key}`}
                key={dataType.key}
              />
            );
          case 'images':
            return <Image label={dataType.label} key={dataType.key} />;
          case 'files':
            return <File label={dataType.label} key={dataType.key} />;
          case 'image':
            return <ImageOne label={dataType.label} key={dataType.key} />;
          case 'file':
            return <FileOne label={dataType.label} key={dataType.key} />;
          case 'boolean':
            return (
              <Boolean
                label={dataType.label}
                source={`section_data.${dataType.key}`}
                key={dataType.key}
              />
            );
          case 'date':
            return (
              <Date
                label={dataType.label}
                source={`section_data.${dataType.key}`}
                key={dataType.key}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
};

export const SecaoUserEdit = (props) => {
  const permissions = usePermissions();
  useNotify();
  return (
    <Edit redirect="list" title={<SecaoUserTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <ReferenceInput
          source="section_id"
          reference="company_sections"
          label="Seção"
          filter={{ company: localStorage.getItem('company') }}
          validate={required()}
        >
          <AutocompleteInput
            fullWidth
            validate={required()}
            source="section_id"
            label="Seção"
            optionText={(choice) => `${choice.title}`}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.section_id) {
              return (
                <SectionsGet
                  section={formData.section_id}
                  permissions={permissions}
                />
              );
            }
            return null;
          }}
        </FormDataConsumer>
        <BooleanInput source="disabled" label="Desabilitar seção?" />
      </SimpleForm>
    </Edit>
  );
};

export const SecaoUserCreate = (props) => {
  const permissions = usePermissions();
  useNotify();
  return (
    <Create redirect="list" {...props} title="Nova Seção">
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <ReferenceInput
          source="section_id"
          reference="company_sections"
          label="Seção"
          filter={{ company: localStorage.getItem('company') }}
          validate={required()}
        >
          <AutocompleteInput
            fullWidth
            validate={required()}
            source="section_id"
            label="Seção"
            optionText={(choice) => `${choice.title}`}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => {
            if (
              !permissions.isLoading &&
              (permissions.permissions.includes('ROLE_SECAO_LIST') ||
                permissions.permissions.includes('SYS_ADMIN'))
            ) {
              if (formData.section_id) {
                return (
                  <SectionsGet
                    section={formData.section_id}
                    permissions={permissions}
                  />
                );
              }
            }
            return null;
          }}
        </FormDataConsumer>
        <BooleanInput source="disabled" label="Desabilitar seção?" />
      </SimpleForm>
    </Create>
  );
};

export default SecaoUser;
